<template>
    <layout-admin-dashboard-view
            :showToolbar="false"
            :backRoute="{name: 'entity-list', params: {entityType:entityType}}">
        <template v-slot:title>
            {{translateTitleCase('entity.crud.editEntityTitle')}}
        </template>

        <template v-slot:default>

            <crud-edit
                    :apiGetItemUrl="'entity/'+entityType+'/'+entityId"
                    :formPath="'entity/'+entityType+'/'+entityId+'/update'"
                    :formAction="'entity/'+entityType+'/'+entityId"
                    :backRoute="{name: 'entity-list', params: {entityType:entityType}}"
                    :successRedirect="{name: 'entity-list', params: {entityType:entityType}}"
            >

            </crud-edit>

        </template>
    </layout-admin-dashboard-view>

</template>

<script>
    import { useStore } from 'vuex'
    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    import { computed, reactive } from 'vue';
    import CrudEdit from '@/client/components/crud/Edit.vue';

    export default {
        props: {
            entityType: {
                type: String,
                default: 'default'
            },
            entityId: {
                type: String,
                default: 'default'
            }
        },

        setup (props) {
            let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props);

            return {asyncOps, asyncOpsReady, asyncStatus}
        },
        components: {CrudEdit},
        data: function () {
            return {
                isReady: false,
            };
        },
        computed: {
            formPath () {
                return 'entity/'+this.entityType+'/'+this.entityId+'/'+'update'
            },
            formAction () {
                return 'entity/'+this.entityType+'/'+this.entityId
            }
        },
        methods: {


        },

    }
</script>

<style scoped lang="scss">

</style>
